
class Filters{
    constructor(datasetsIds, seedFamilies, miRnaIds, sites, geneIds, regions, features){
        this.datasetsIds = datasetsIds;
        this.seedFamilies = seedFamilies; 
        this.miRnaIds = miRnaIds;
        this.sites = sites;
        this.geneIds = geneIds;
        this.regions = regions;
        this.features = features;
    }

}

export default Filters;